<script>
import hideKey from "./hideKey.vue";
import {
  getDocumentDetail,
  getDocumentDetailReadOnly,
  deleteDocumentDetail,
  editDocumentDetail,
  addDocumentDetail,
  addHeadDocumentDetail,
  deleteHeadDocumentDetail,
  changeHeadDocumentDetail,
} from "@/api/document";
export default {
  components: {
    hideKey,
  },
  props: {
    show: Boolean,
    current: Object,
    isReadOnly: Boolean,
  },
  watch: {
    show: {
      handler(nv, ov) {
        if (nv) {
          this.handleGetDetial();
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      searchForm: {},
      detialList: [],
      detialLoading: false,
      showAdd: false,
      form: [],
      addLoading: false,
      noData: false,
      pageNum: 1,
      pageSize: 50,
      total: 0,
      showHide: false,
      currentData: {},
      keyList: [],
      activeIndex: null,
      // 列操作
      showAddRow: false,
      addRowData: "",
      currentHead: "",
      addRowLoading: false,
      isEditHead: false,
      tmpScrollTop: -1,
    };
  },
  methods: {
    handleActive(index) {
      this.activeIndex = index;
    },
    handleChangeHead(data) {
      if (this.isReadOnly) {
        return false;
      }
      this.isEditHead = true;
      this.currentHead = data.head;
      this.addRowData = data.head;
      this.showAddRow = true;
    },
    handlSubAddRow() {
      if (!this.addRowData) {
        return this.$message.error("请填写列名称");
      }
      this.addRowLoading = true;
      if (this.isEditHead) {
        changeHeadDocumentDetail({
          excelManageId: this.current.id,
          beforeHead: this.currentHead,
          updateHead: this.addRowData,
        })
          .then((res) => {
            this.$message.success("修改成功");
            this.handleGetDetial();
            this.handleResetRow();
          })
          .finally(() => {
            this.addRowLoading = false;
          });
      } else {
        addHeadDocumentDetail({
          excelManageId: this.current.id,
          beforeHead: this.currentHead,
          addHead: this.addRowData,
        })
          .then((res) => {
            this.$message.success("新增成功");
            this.handleGetDetial();
            this.handleResetRow();
          })
          .finally(() => {
            this.addRowLoading = false;
          });
      }
    },
    handleResetRow() {
      this.addRowData = "";
      this.showAddRow = false;
    },
    handleAddRow(head) {
      this.isEditHead = false;
      this.currentHead = head;
      this.showAddRow = true;
    },
    handleDeleteRow(data) {
      this.$confirm("确定要删除此列数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteHeadDocumentDetail({
          excelManageId: this.current.id,
          head: data.head,
        }).then((res) => {
          this.$message.success("删除成功");
          this.handleGetDetial();
        });
      });
    },
    handleSetHideKey() {
      this.showHide = true;
    },
    handleStaticPageChange(page) {
      this.pageNum = page;
    },
    handleAddDetail() {
      this.showAdd = true;
    },
    handleEdit(data) {
      if (this.isReadOnly) {
        return;
      }
      this.$prompt("请输入修改内容", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputType: "textarea",
        inputValue: data.content,
      }).then(({ value }) => {
        editDocumentDetail(data.id, {
          content: value,
        }).then((res) => {
          this.$message.success("修改成功");
          this.handleGetDetial();
        });
      });
    },
    handleDelete(data) {
      this.$confirm("确定要删除该条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteDocumentDetail(this.current.id, {
          group: data.group,
        }).then((res) => {
          this.$message.success("删除成功");
          this.handleGetDetial();
        });
      });
    },
    handleGetDetial() {
      this.pageNum = 1;
      this.detialLoading = true;
      this.detialList = [];
      this.$forceUpdate();
      if (this.isReadOnly) {
        getDocumentDetailReadOnly(this.current.id, {
          ...this.searchForm,
        })
          .then((res) => {
            this.detialList = res;

            if (this.detialList.length === 0) {
              this.noData = true;
            } else {
              this.noData = false;
              this.total = res[0].list.length;
            }
            this.form = this.detialList.map((item) => {
              return {
                excelManageId: this.current.id,
                head: item.head,
                content: "",
              };
            });
          })
          .finally(() => {
            this.detialLoading = false;
          });
      } else {
        getDocumentDetail(this.current.id, {
          ...this.searchForm,
        })
          .then((res) => {
            this.detialList = res;

            if (this.detialList.length == 0) {
              this.noData = true;
            } else {
              this.total = res[0].list.length;
            }
            this.form = this.detialList.map((item) => {
              return {
                excelManageId: this.current.id,
                head: item.head,
                content: "",
              };
            });
            this.keyList = this.detialList.map((item) => {
              return item.head;
            });
          })
          .finally(() => {
            this.detialLoading = false;
          });
      }
    },
    handleReset() {
      this.form = [];
    },
    handlSubAdd() {
      this.addLoading = true;
      addDocumentDetail(this.form)
        .then((res) => {
          this.$message.success("新增成功");
          this.handleGetDetial();
        })
        .finally(() => {
          this.showAdd = false;
          this.addLoading = false;
        });
    },
  },
  mounted() {
    // 动态设置表头位置
    const that = this;
    document.getElementById("userRankTable").onscroll = function () {
      if (this.clientHeight + parseInt(this.scrollTop) === this.scrollHeight) {
        if (that.pageNum * that.pageSize < that.total) {
          that.pageNum++;
        } else {
          if (that.tmpScrollTop == parseInt(this.scrollTop)) {
            return;
          }
          that.tmpScrollTop = parseInt(this.scrollTop);
          return that.$message.warning("已经到底了");
        }
      }
      that.tmpScrollTop = -1;
      document.getElementById("userRankTableHeader").style.top =
        document.getElementById("userRankTable").scrollTop + "px";
    };
  },
};
</script>

<template>
  <div class="detail-box">
    <div class="operation-tab">
      <el-button @click="handleGetDetial()" style="margin-right: 20px"
        >刷新数据</el-button
      >
      <el-input
        v-model="searchForm.content"
        clearable
        placeholder="请输入内容"
        @clear="handleGetDetial"
        style="width: 200px; margin-right: 20px"
      >
      </el-input>
      <el-input
        v-model="searchForm.content1"
        clearable
        placeholder="请输入内容2"
        @clear="handleGetDetial"
        style="width: 200px; margin-right: 20px"
      >
      </el-input>
      <el-input
        v-model="searchForm.content2"
        clearable
        placeholder="请输入内容3"
        @clear="handleGetDetial"
        style="width: 200px; margin-right: 20px"
      >
      </el-input>
      <el-button @click="handleGetDetial" type="primary"> 搜索</el-button>
      <el-button
        v-if="!isReadOnly"
        :disabled="!detialList.length"
        @click="handleAddDetail"
        class="input-update-button"
        slot="append"
        type="success"
        >新增内容</el-button
      >
      <el-button
        v-if="!isReadOnly"
        :disabled="!detialList.length"
        @click="handleSetHideKey"
        class="input-update-button"
        slot="append"
        type="success"
        >设置隐藏字段</el-button
      >
      <!--  -->
    </div>
    <p v-if="noData" style="width: 100%; text-align: center; margin-top: 20px">
      暂无数据
    </p>
    <div v-loading="detialLoading" class="custom-table">
      <div style="overflow: auto; position: relative" id="userRankTable">
        <!-- 表格头部 -->
        <div
          class="customTable table-header"
          id="userRankTableHeader"
          style="min-width: 100%"
        >
          <!-- 表格头部操作列 -->
          <div v-if="detialList.length && !isReadOnly" class="head-box">
            <div class="item-head" style="width: 80px">
              <p>操作</p>
              <div class="add-box" v-if="!isReadOnly">
                <span @click="handleAddRow(null)"
                  ><i class="el-icon-plus"></i
                ></span>
              </div>
            </div>
          </div>
          <!-- 表格头部普通列 -->
          <div
            class="head-box"
            v-for="(item, index) in detialList"
            :key="index + 'header'"
          >
            <div class="item-head">
              <p style="cursor: pointer" @click="handleChangeHead(item)">
                {{ item.head }}
              </p>
              <div class="add-box" v-if="!isReadOnly">
                <span @click="handleDeleteRow(item)"
                  ><i class="el-icon-minus"></i
                ></span>
                <span @click="handleAddRow(item.head)"
                  ><i class="el-icon-plus"></i
                ></span>
              </div>
            </div>
          </div>
        </div>
        <!-- 表体 -->
        <div
          class="customTable table-body"
          style="min-width: 100%; height: 650px"
        >
          <!-- 操作表体 -->
          <div
            v-if="detialList.length && !isReadOnly"
            class="table-item"
            style="width: 80px"
          >
            <ul
              class="single-user"
              style="width: 80px"
              v-for="(item2, index2) in detialList[0].list.slice(
                0,
                pageNum * pageSize
              )"
              :key="index2"
            >
              <li>
                <el-button
                  @click="handleDelete(item2)"
                  type="danger"
                  class="button-small"
                  >删除</el-button
                >
              </li>
            </ul>
          </div>
          <!-- 普通表体 -->
          <div
            class="table-item"
            v-for="(item, index) in detialList"
            :key="index"
          >
            <ul
              class="single-user"
              v-for="(item2, index2) in item.list.slice(0, pageNum * pageSize)"
              :class="{ activeRow: index2 == activeIndex }"
              @click="handleActive(index2)"
              :key="index2"
            >
              <li>
                <el-popover
                  v-if="item2.content.length > 10"
                  placement="right"
                  width="200"
                  trigger="hover"
                  :content="item2.content"
                >
                  <p
                    class="table-text"
                    @dblclick="handleEdit(item2)"
                    slot="reference"
                  >
                    {{ item2.content }}
                  </p>
                </el-popover>
                <p v-else @dblclick="handleEdit(item2)" class="table-text">
                  {{ item2.content }}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- <el-pagination
        style="margin: 20px 0; text-align: right"
        background
        @current-change="handleStaticPageChange"
        :current-page="pageNum"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination> -->
    </div>
    <!-- 新增 -->
    <el-dialog
      title="新增内容"
      :visible.sync="showAdd"
      width="400px"
      top="60px"
      @close="handleReset"
      append-to-body
    >
      <div class="form-box">
        <el-form ref="form" label-width="100px">
          <el-form-item
            v-for="(item, index) in form"
            :label="item.head"
            :key="index"
          >
            <el-input style="width: 200px" v-model="item.content"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="showAdd = false">取消</el-button>
        <el-button type="primary" v-loading="addLoading" @click="handlSubAdd"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 设置字段 -->
    <el-drawer
      title="设置字段"
      :visible.sync="showHide"
      size="40%"
      :destroy-on-close="true"
      :show-close="true"
      append-to-body
      :before-close="() => (showHide = false)"
    >
      <hideKey
        @cancel="() => (showHide = false)"
        v-if="showHide"
        :show="showHide"
        :keyList="keyList"
        :id="current.id"
      />
    </el-drawer>
    <!-- 添加列 -->
    <el-dialog
      :title="isEditHead ? '编辑列' : '新增列'"
      :visible.sync="showAddRow"
      width="400px"
      top="60px"
      @close="handleResetRow"
      append-to-body
    >
      <div class="form-box">
        <el-form ref="form" label-width="100px">
          <el-form-item label="列名称">
            <el-input style="width: 200px" v-model="addRowData"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="add-row-footer">
        <el-button @click="showAddRow = false">取消</el-button>
        <el-button
          type="primary"
          v-loading="addRowLoading"
          @click="handlSubAddRow"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<style lang="scss" scoped>
.detail-box {
  padding: 20px;
}
.custom-table {
  margin-top: 20px;
  min-height: 700px;
}
// 自定义表格
.customTable {
  display: flex;
  flex-wrap: nowrap;
}
.table-item {
  float: left;
  .table-text {
    cursor: pointer;
    width: 160px;
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.item-head {
  /* position: absolute;
  top: 0;
  left: 0; */
  width: 170px;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  padding-left: 10px;
  line-height: 40px;
  border: 1px solid #ebeef5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    .add-box {
      display: flex;
    }
  }
  // border-bottom: none;
}
.single-user {
  /* margin-top: 40px; */
  border: 1px solid #ebeef5;
  width: 170px;
  height: 40px;
  padding: 5px;
  font-size: 14px;
  color: #272727;
  line-height: 20px;
}
// 表头浮动
.table-header {
  position: absolute;
  background-color: #fff;
  top: 0;
  left: 0;
}
.table-body {
  margin-top: 40px;
}
.form-box {
  max-height: 600px;
  overflow-y: auto;
}

// 添加删除列
.add-box {
  width: 40px;
  display: none;
  align-items: center;
  justify-content: space-around;
  span {
    font-size: 13px;
    cursor: pointer;
    .el-icon-minus {
      color: #f56c6c;
      font-weight: bold;
    }
    .el-icon-plus {
      color: #14c090;
      font-weight: bold;
    }
  }
}
.add-box-edit {
  width: 20px;
}
.add-row-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.activeRow {
  background-color: #e8f4f5;
}
</style>
