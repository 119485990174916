
<script>
import {
  getDocumentDetailHideKey,
  changeDocumentDetailHideKey,
} from "@/api/document";
export default {
  props: {
    keyList: {
      default: () => [],
      type: Array,
    },
    id: Number,
  },
  data() {
    return {
      checkAll: false,
      isIndeterminate: true,
      checkedList: [],
      loading: false,
    };
  },
  methods: {
    handleCheckAllChange(val) {
      this.checkedList = val ? this.keyList : [];
      this.isIndeterminate = false;
    },
    handleCheckedChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.keyList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.keyList.length;
    },
    handleSubmit() {
      changeDocumentDetailHideKey(this.id, {
        head: this.checkedList.join(","),
      }).then((res) => {
        this.$message.success("修改成功");
        this.$emit("cancel");
      });
    },
  },
  created() {
    getDocumentDetailHideKey(this.id).then((res) => {
      this.checkedList = res;
    });
  },
};
</script>

<template>
  <div class="hide-box">
    <div class="check-box">
      <el-checkbox
        :indeterminate="isIndeterminate"
        v-model="checkAll"
        @change="handleCheckAllChange"
        >全选</el-checkbox
      >
      <div style="margin: 15px 0"></div>
      <el-checkbox-group v-model="checkedList" @change="handleCheckedChange">
        <el-checkbox
          style="width: 100px"
          v-for="key in keyList"
          :label="key"
          :key="key"
          >{{ key }}</el-checkbox
        >
      </el-checkbox-group>
    </div>
    <div class="demo-drawer__footer">
      <el-button @click="$emit('cancel')">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="loading"
        >确定</el-button
      >
    </div>
  </div>
</template>


<style lang='scss' scoped>
.hide-box {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  .check-box {
    flex: 1;
  }
  .demo-drawer__footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.el-checkbox + .el-checkbox {
  margin-left: 0 !important;
}
</style>